import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = { class: "opModal" };
const _hoisted_2 = { class: "form-group" };
const _hoisted_3 = { class: "d-flex justify-content-center" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
            _createElementVNode("label", null, _toDisplayString(_ctx.label), 1),
            _withDirectives(_createElementVNode("input", {
                type: "text",
                class: "form-control",
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event) => ((_ctx.text) = $event))
            }, null, 512), [
                [_vModelText, _ctx.text]
            ])
        ]),
        _createElementVNode("div", _hoisted_3, [
            _createElementVNode("button", {
                type: "button",
                class: "btn btn-green",
                onClick: _cache[1] || (_cache[1] =
                    //@ts-ignore
                    (...args) => (_ctx.ok && _ctx.ok(...args)))
            }, "Ok")
        ])
    ]));
}
