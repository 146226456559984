export default () => {
    return [
        {
            path: '/richiestePagamentoUser',
            name: 'richiestePagamentoUser',
            component: () => import('./views/richiestePagamentoUser.vue'),
        },
        {
            path: '/richiestePagamentoCommerciali',
            name: 'richiestePagamentoCommerciali',
            component: () => import('./views/richiestePagamentoCommerciali.vue'),
        },
    ];
};
