export default () => {
    return [
        {
            path: '/shopOffersGestim',
            name: 'shopOffersGestim',
            component: () => import('./views/shopOffersGestim.vue'),
        },
        {
            path: '/shopOfferGestim/:identifier',
            name: 'shopOfferDetailGestim',
            component: () => import('./views/shopOfferGestimDetail.vue'),
        },
    ];
};
