export default () => {
    return [
        {
            path: '/users',
            name: 'users',
            component: () => import('./views/users.vue'),
        },
        {
            path: '/users/:identifier',
            name: 'userDetail',
            component: () => import('./views/userDetail.vue'),
        },
    ];
};
