import { createWebHistory } from 'vue-router';
import { createRouter } from 'vue-router';
import store from './store';
import login from '@/views/login';
import mappa from './views/mappa';
import richiestePagamentoUser from './views/richiestePagamentoUser';
import richiestePagamento from './views/richiestePagamento';
import user from './views/user';
import referral from './views/referral';
import tasks from './views/tasks';
import myTasks from './views/myTasks';
import tabellaQualita from './views/tabellaQualita';
import product from './views/product';
import config from './views/config';
import shopOffer from './views/shopOffer';
import shopOfferGestim from './views/shopOfferGestim';
import produzioneTask from './views/produzioneTask';
import mailTemplate from './views/mailTemplate';
import customerService from './views/customerService';
import statistiche from './views/statistiche';
import mailSentLog from './views/mailSentLog';
import registroAccessi from './views/registroAccessi';
import customer from './views/customer';
import riepilogoGiornaliero from './views/riepilogoGiornaliero';
import riepilogoMonths from './views/riepilogoMonths';
import dictionary from './views/dictionary';
import produzioneCommerciale from './views/produzioneCommerciale';
let routes = [
    ...login.routes(),
    {
        path: '/',
        name: 'trelloLayout',
        component: () => import('@/views/trelloLayout.vue'),
        children: [
            {
                path: '/trello',
                name: 'trello',
                component: () => import('@/views/tasks/views/trello.vue'),
            },
            {
                path: '/trelloCollaboratori',
                name: 'trelloCollaboratori',
                component: () => import('@/views/tasks/views/trelloCollaboratori.vue'),
            },
            {
                path: '/customersTrello',
                name: 'customersTrello',
                component: () => import('@/views/customerService/views/customersTrello.vue'),
            },
        ]
    },
    {
        path: '/',
        name: 'layout',
        component: () => import('@/views/layout.vue'),
        children: [
            ...mappa.routes(),
            ...richiestePagamentoUser.routes(),
            ...richiestePagamento.routes(),
            ...user.routes(),
            ...referral.routes(),
            ...tasks.routes(),
            ...myTasks.routes(),
            ...tabellaQualita.routes(),
            ...product.routes(),
            ...config.routes(),
            ...shopOffer.routes(),
            ...shopOfferGestim.routes(),
            ...produzioneTask.routes(),
            ...produzioneCommerciale.routes(),
            ...mailTemplate.routes(),
            ...riepilogoGiornaliero.routes(),
            ...riepilogoMonths.routes(),
            ...customerService.routes(),
            ...statistiche.routes(),
            ...mailSentLog.routes(),
            ...registroAccessi.routes(),
            ...customer.routes(),
            ...dictionary.routes()
        ]
    },
];
let adminRoutes = () => [
    { name: 'Gestione', children: [
            { text: 'Clienti', path: '/customers' },
            { text: 'Clienti OnOffice', path: '/onOfficeCustomers' },
            { text: 'Customer service', path: '/customerService' },
            { text: 'Task', path: '/tasks' },
            { text: 'Miei task', path: '/myTasks' },
            { text: 'Referral', path: '/referrals' },
        ] },
    { name: 'Monitoraggio', children: [
            { text: 'Tabella qualità', path: '/tabellaQualita' },
            { text: "Statistiche", path: '/stats' },
            { text: "Riepilogo giornaliero", path: '/riepilogos' },
            { text: "Riepilogo mensile", path: '/riepilogoMonths' },
            { text: 'Mappa', path: '/mappa' },
            { text: "Log email sistema", path: '/mailSentLog' },
        ] },
    { name: 'Negozio', children: [
            { text: 'Prodotti', path: '/products' },
            { text: 'Offerte negozio', path: '/shopOffers' },
            { text: 'Offerte GESTIM', path: '/shopOffersGestim' },
        ] },
    { name: 'Strumenti', children: [
            { text: "Template Email", path: '/mailTemplates' },
            { text: 'Registro accessi', path: '/registroAccessi' },
            { text: 'Utenti', path: '/users' },
            { text: 'Rich. pag. architetti', path: '/richiestePagamentoUser' },
            { text: 'Rich. pag. commerciali', path: '/richiestePagamentoCommerciali' },
            { text: "Configurazione", path: '/config' },
            { text: 'Traduzioni', path: '/dictionary' },
        ] },
];
let officeRoutes = () => [
    { name: 'Gestione', children: [
            { text: 'Clienti', path: '/customers' },
            { text: 'Clienti OnOffice', path: '/onOfficeCustomers' },
            { text: 'Customer service', path: '/customerService' },
            { text: 'Task', path: '/tasks' },
            { text: 'Miei task', path: '/myTasks' },
            { text: 'Referral', path: '/referrals' },
        ] },
    { name: 'Monitoraggio', children: [
            { text: 'Tabella qualità', path: '/tabellaQualita' },
            { text: "Statistiche", path: '/stats' },
            { text: "Riepilogo giornaliero", path: '/riepilogos' },
            { text: "Riepilogo mensile", path: '/riepilogoMonths' },
            { text: 'Mappa', path: '/mappa' },
        ] },
    { name: 'Strumenti', children: [
            { text: "Template Email", path: '/mailTemplates' },
            { text: 'Utenti', path: '/users' },
            { text: 'Rich. pag. architetti', path: '/richiestePagamentoUser' },
            { text: 'Rich. pag. commerciali', path: '/richiestePagamentoCommerciali' },
            { text: 'Registro accessi', path: '/registroAccessi' },
            { text: "Log email sistema", path: '/mailSentLog' },
        ] }
];
let collaboratoreRoutes = () => [
    { name: 'Area di lavoro', children: [
            { text: 'Pannello task', path: '/trelloCollaboratori' },
            { text: 'Produzione', path: '/produzioneTask' },
            { text: 'Richieste pagamento', path: '/richiestePagamento' },
        ] }
];
let commercialeRoutes = () => [
    { name: 'Area di lavoro', children: [
            { text: 'Clienti', path: '/customers' },
            { text: 'Clienti OnOffice', path: '/onOfficeCustomers' },
            { text: 'Trello clienti', path: '/customersTrello' },
            { text: 'Produzione', path: '/produzioneCommerciale' },
            { text: 'Richieste pagamento', path: '/richiestePagamento' },
        ] }
];
let clienteRoutes = () => [
    { text: 'Dashboard', path: '/customers/' + store.state.loginResponse.userIdentifier + '/dash', },
];
export let menuRoutes = {
    adminRoutes,
    officeRoutes,
    collaboratoreRoutes,
    commercialeRoutes,
    clienteRoutes,
};
let _router = createRouter({
    history: createWebHistory(),
    routes: routes,
    scrollBehavior: (to, from, savedPosition) => {
        if (savedPosition) {
            window.scrollTo(savedPosition ? savedPosition.left : 0, savedPosition ? savedPosition.top : 0);
        }
        else {
            if (window.innerWidth > 860)
                window.scrollTo(0, 0);
        }
    },
});
_router.beforeEach((to, from, next) => {
    if (store.state.isCliente() || store.state.isClienteBig()) {
        if (to.meta.forCliente) {
            next();
        }
    }
    else {
        next();
    }
});
export default _router;
