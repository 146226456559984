import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-6ef377c4"), n = n(), _popScopeId(), n);
const _hoisted_1 = { class: "form-group" };
const _hoisted_2 = {
    key: 1,
    class: "preview"
};
const _hoisted_3 = ["src"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_file_dropper = _resolveComponent("file-dropper");
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("label", null, [
            _renderSlot(_ctx.$slots, "default", {}, undefined, true)
        ]),
        (!_ctx.localValue)
            ? (_openBlock(), _createBlock(_component_file_dropper, {
                key: 0,
                modelValue: _ctx.fileToUpload,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event) => ((_ctx.fileToUpload) = $event))
            }, null, 8, ["modelValue"]))
            : (_openBlock(), _createElementBlock("div", _hoisted_2, [
                _createElementVNode("img", { src: _ctx.localValue }, null, 8, _hoisted_3),
                _createElementVNode("button", {
                    type: "button",
                    onClick: _cache[1] || (_cache[1] = ($event) => (_ctx.localValue = null))
                }, "Modifica")
            ]))
    ]));
}
