import store from "./store";
export function parseTemplate(input, data) {
    let currentString = input;
    let currentPosition = 0;
    let ris = "";
    let leftBracketPosition = input.indexOf("{{");
    let rightBracketPosition = input.indexOf("}}");
    while (leftBracketPosition > -1 && rightBracketPosition > -1) {
        ris += currentString.substring(currentPosition, leftBracketPosition);
        let parola = currentString.substring(leftBracketPosition + 2, rightBracketPosition);
        let link = parola;
        let interpolatedValue = '' + data[parola];
        ris += interpolatedValue;
        currentString = currentString.substring(rightBracketPosition + 2);
        leftBracketPosition = currentString.indexOf("{{");
        rightBracketPosition = currentString.indexOf("}}");
    }
    ris += currentString.substring(rightBracketPosition);
    return ris;
}
export function insertAllLanguages(localized) {
    var enabledLanguages = store.state.consts.enabledLanguages;
    enabledLanguages.forEach(lang => {
        var keys = Object.keys(localized.values);
        var found = keys.find(x => x == lang);
        if (!found) {
            localized.values[lang] = "";
        }
    });
}
export function getValueByProp(input, propString) {
    let props = propString.split('.');
    let ris = input;
    props.forEach(x => {
        if (ris != null)
            ris = ris[x];
    });
    return ris;
}
