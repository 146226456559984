import { vModelText as _vModelText, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    return _withDirectives((_openBlock(), _createElementBlock("input", {
        type: "search",
        ref: "autocomplete",
        class: "form-control",
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event) => ((_ctx.typedAddress) = $event)),
        onInput: _cache[1] || (_cache[1] =
            //@ts-ignore
            (...args) => (_ctx.onInput && _ctx.onInput(...args))),
        autocomplete: "chrome-off",
        placeholder: "Enter a location"
    }, null, 544)), [
        [_vModelText, _ctx.typedAddress]
    ]);
}
