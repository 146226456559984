import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = { class: "monthNavigator" };
const _hoisted_2 = { class: "left" };
const _hoisted_3 = { class: "center" };
const _hoisted_4 = { class: "right" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_op_date = _resolveComponent("op-date");
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
            _createElementVNode("button", {
                type: "button",
                class: "btn btn-secondary",
                onClick: _cache[0] || (_cache[0] =
                    //@ts-ignore
                    (...args) => (_ctx.prev && _ctx.prev(...args)))
            }, " < ")
        ]),
        _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_op_date, {
                modelValue: _ctx.localValue,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event) => ((_ctx.localValue) = $event))
            }, null, 8, ["modelValue"])
        ]),
        _createElementVNode("div", _hoisted_4, [
            _createElementVNode("button", {
                type: "button",
                class: "btn btn-secondary",
                onClick: _cache[2] || (_cache[2] =
                    //@ts-ignore
                    (...args) => (_ctx.next && _ctx.next(...args)))
            }, " > ")
        ])
    ]));
}
