import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = { class: "form-group" };
const _hoisted_2 = { class: "taskTitle" };
const _hoisted_3 = {
    key: 1,
    class: "preview"
};
const _hoisted_4 = ["src"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_file_dropper = _resolveComponent("file-dropper");
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("label", _hoisted_2, _toDisplayString(_ctx.label), 1),
        _createElementVNode("button", {
            type: "button",
            class: "btn btn-danger ml-2",
            onClick: _cache[0] || (_cache[0] =
                //@ts-ignore
                (...args) => (_ctx.unset && _ctx.unset(...args)))
        }, "Elimina"),
        (!_ctx.localPreview)
            ? (_openBlock(), _createBlock(_component_file_dropper, {
                key: 0,
                modelValue: _ctx.localValue,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event) => ((_ctx.localValue) = $event))
            }, null, 8, ["modelValue"]))
            : (_openBlock(), _createElementBlock("div", _hoisted_3, [
                _createElementVNode("img", { src: _ctx.localPreview }, null, 8, _hoisted_4),
                _createElementVNode("button", {
                    type: "button",
                    onClick: _cache[2] || (_cache[2] = ($event) => (_ctx.localPreview = null))
                }, "Modifica")
            ]))
    ]));
}
