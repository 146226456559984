import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-30b3a604"), n = n(), _popScopeId(), n);
const _hoisted_1 = { class: "savedCardContainer" };
const _hoisted_2 = { key: 0 };
const _hoisted_3 = ["onClick"];
const _hoisted_4 = { class: "info" };
const _hoisted_5 = { class: "d-flex align-items-center justify-content-between" };
const _hoisted_6 = { class: "expirationDate" };
const _hoisted_7 = {
    key: 0,
    class: "d-flex align-items-center justify-content-between"
};
const _hoisted_8 = { class: "cardHolder mb-0" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_ctx.loaded)
            ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.cards, (card, index) => {
                    return (_openBlock(), _createElementBlock("div", {
                        class: _normalizeClass(["savedCard", card.cardId == _ctx.modelValue ? 'active' : '']),
                        key: index,
                        onClick: ($event) => (_ctx.selectCard(card))
                    }, [
                        _createElementVNode("div", _hoisted_4, [
                            _createElementVNode("div", _hoisted_5, [
                                _createElementVNode("strong", null, "XXXX XXXX XXXX " + _toDisplayString(card.last4), 1),
                                _createElementVNode("span", _hoisted_6, _toDisplayString(card.expMonth) + "/" + _toDisplayString(card.expYear), 1)
                            ]),
                            (card.holder)
                                ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                                    _createElementVNode("span", _hoisted_8, _toDisplayString(card.holder), 1)
                                ]))
                                : _createCommentVNode("", true)
                        ])
                    ], 10, _hoisted_3));
                }), 128))
            ]))
            : _createCommentVNode("", true)
    ]));
}
