import { CommonServices } from '@/services/CommonServices';
import { baseUrl } from '@/config';
class _CustomerServices {
    DownloadAttachment(customerIdentifier, fileIdentifier) {
        let encodedFileId = encodeURIComponent(fileIdentifier);
        window.open(baseUrl + "api/Customer/DownloadAttachment?customerIdentifier=" + customerIdentifier + "&fileIdentifier=" + encodedFileId, "_blank");
    }
    UploadAllegatoInterno(vm, onProgress) {
        return CommonServices.uploadFileToUrl(baseUrl + "api/Customer/UploadAllegatoInterno", vm, onProgress);
    }
    UploadAvatarUrl(vm) {
        return CommonServices.uploadFileToUrl(baseUrl + "api/Customer/UploadAvatarUrl", vm);
    }
}
export let CustomerServices = new _CustomerServices();
