import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import '@/css/lato.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import '@/css/op-table.css';
import '@/css/style.css';
import DIRECTIVES from '@/directives';
import FILTERS from '@/filters';
import { AppConfigClient } from './services/Services';
import OpModal from '@/plugins/modalPlugin';
AppConfigClient.getConfig()
    .then(x => {
    store.state.config = x;
    const app = createApp(App)
        .use(store)
        .use(router)
        .use(OpModal);
    for (let prop in DIRECTIVES) {
        app.directive(prop, DIRECTIVES[prop]);
    }
    app.config.globalProperties.$filters = FILTERS;
    const ComponentContext = require.context('./components', true, /\.vue$/i);
    ComponentContext.keys().forEach(fileName => {
        var component = ComponentContext(fileName);
        let componentName = fileName.split('/').pop().split('.')[0];
        app.component(componentName, component.default || component);
    });
    app.mount('#app');
});
import { CommonServices } from '@/services/CommonServices';
CommonServices.showSpinnerCb = () => {
    store.state.globalSpinner++;
};
CommonServices.hideSpinnerCb = () => {
    if (store.state.globalSpinner > 0)
        store.state.globalSpinner--;
};
CommonServices.unauthorizedCb = () => {
    if (router.currentRoute.value.name != 'login') {
        router.push('/?redirect=' + router.currentRoute.value.fullPath);
    }
};
import 'swiper/css/swiper.min.css';
import 'toastr/build/toastr.min.css';
import toastr from 'toastr';
toastr.options = {
    closeButton: false,
    debug: false,
    newestOnTop: false,
    progressBar: true,
    positionClass: "toast-top-right",
    preventDuplicates: false,
    showDuration: 300,
    hideDuration: 1000,
    timeOut: 4000,
    extendedTimeOut: 1000,
    showEasing: "swing",
    hideEasing: "linear",
    showMethod: "fadeIn",
    hideMethod: "fadeOut"
};
window.toastr = toastr;
