export default () => {
    return [
        {
            path: '/onOfficeCustomers',
            name: 'onOfficeCustomers',
            component: () => import('./views/onOfficeCustomers.vue'),
        },
        {
            path: '/customers',
            name: 'customers',
            component: () => import('./views/customers.vue'),
        },
        {
            path: '/customers/:customerIdentifier',
            name: 'customerDetail',
            component: () => import('./views/customerDetail.vue'),
        },
        {
            path: '/customers/:customerIdentifier/dash',
            name: 'customerDash',
            meta: { clearCache: true, forCliente: true },
            redirect: (to) => {
                return { name: 'customerDash_workInProgress', params: to.params };
            },
            component: () => import('./views/customerDash.vue'),
            children: [
                {
                    path: 'workInProgress',
                    name: 'customerDash_workInProgress',
                    component: () => import('./views/workInProgress.vue'),
                    meta: { forCliente: true }
                },
                {
                    path: 'buyCredits',
                    name: 'customerDash_buyCredits',
                    component: () => import('./views/buyCredits.vue'),
                    meta: { forCliente: true }
                },
                {
                    path: 'order',
                    name: 'customerDash_order',
                    component: () => import('./views/order.vue'),
                    meta: { forCliente: true }
                },
                {
                    path: 'postOrder/:productIdentifier',
                    name: 'customerDash_postOrder',
                    component: () => import('./views/order.vue'),
                    meta: { forCliente: true }
                },
                {
                    path: 'children',
                    name: 'customerDash_children',
                    component: () => import('./views/children.vue'),
                    meta: { forCliente: true }
                },
                {
                    path: 'referral',
                    name: 'customerDash_referral',
                    component: () => import('./views/referral.vue'),
                    meta: { forCliente: true }
                },
            ]
        },
    ];
};
