import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    return (_openBlock(), _createElementBlock("ul", null, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.validCredits, (value, key, i) => {
            return (_openBlock(), _createElementBlock("li", { key: i }, [
                _createTextVNode(_toDisplayString(value) + " ", 1),
                _createElementVNode("b", null, _toDisplayString(key), 1)
            ]));
        }), 128))
    ]));
}
