import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-3e3ae59f"), n = n(), _popScopeId(), n);
const _hoisted_1 = { class: "_starContainer" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("i", {
            class: _normalizeClass(["fa-star", { 'fas': _ctx.modelValue > 0, 'far': _ctx.modelValue <= 1 }]),
            onClick: _cache[0] || (_cache[0] = ($event) => (_ctx.emitValue(1)))
        }, null, 2),
        _createElementVNode("i", {
            class: _normalizeClass(["fa-star", { 'fas': _ctx.modelValue > 1, 'far': _ctx.modelValue <= 2 }]),
            onClick: _cache[1] || (_cache[1] = ($event) => (_ctx.emitValue(2)))
        }, null, 2),
        _createElementVNode("i", {
            class: _normalizeClass(["fa-star", { 'fas': _ctx.modelValue > 2, 'far': _ctx.modelValue <= 3 }]),
            onClick: _cache[2] || (_cache[2] = ($event) => (_ctx.emitValue(3)))
        }, null, 2),
        _createElementVNode("i", {
            class: _normalizeClass(["fa-star", { 'fas': _ctx.modelValue > 3, 'far': _ctx.modelValue <= 4 }]),
            onClick: _cache[3] || (_cache[3] = ($event) => (_ctx.emitValue(4)))
        }, null, 2),
        _createElementVNode("i", {
            class: _normalizeClass(["fa-star", { 'fas': _ctx.modelValue > 4, 'far': _ctx.modelValue <= 5 }]),
            onClick: _cache[4] || (_cache[4] = ($event) => (_ctx.emitValue(5)))
        }, null, 2)
    ]));
}
