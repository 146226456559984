export default () => {
    return [
        {
            path: '/shopOffers',
            name: 'shopOffers',
            component: () => import('./views/shopOffers.vue'),
        },
        {
            path: '/shopOffer/:identifier',
            name: 'shopOfferDetail',
            component: () => import('./views/shopOfferDetail.vue'),
        },
    ];
};
