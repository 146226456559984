export default () => {
    return [
        {
            path: '/',
            name: 'login',
            component: () => import('./views/login.vue'),
            meta: { forCliente: true }
        },
        {
            path: '/register',
            name: 'register',
            component: () => import('./views/register.vue'),
            meta: { forCliente: true }
        },
        {
            path: '/recoverPassword',
            name: 'recoverPassword',
            component: () => import('./views/recoverPassword.vue'),
            meta: { forCliente: true }
        },
        {
            path: '/resetPassword',
            name: 'resetPassword',
            component: () => import('./views/resetPassword.vue'),
            meta: { forCliente: true }
        },
    ];
};
