export default () => {
    return [
        {
            path: '/mailTemplates',
            name: 'mailTemplates',
            component: () => import('./views/mailTemplates.vue'),
        },
        {
            path: '/mailTemplate/:identifier',
            name: 'mailTemplateDetail',
            component: () => import('./views/mailTemplateDetail.vue'),
        },
    ];
};
