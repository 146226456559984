export default () => {
    return [
        {
            path: '/products',
            name: 'products',
            component: () => import('./views/products.vue'),
        },
        {
            path: '/products/:productIdentifier',
            name: 'productDetail',
            component: () => import('./views/productDetail.vue'),
        },
    ];
};
