import { CommonServices } from '@/services/CommonServices';
import { baseUrl } from '@/config';
class _ProductServices {
    UploadProductImage(vm) {
        return CommonServices.uploadFileToUrl(baseUrl + "api/Product/UploadProductImage", vm);
    }
    UploadPostOrderImage(vm) {
        return CommonServices.uploadFileToUrl(baseUrl + "api/Product/UploadPostOrderImage", vm);
    }
}
export let ProductServices = new _ProductServices();
