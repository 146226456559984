import { createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-327d3742"), n = n(), _popScopeId(), n);
const _hoisted_1 = ["href"];
const _hoisted_2 = ["src"];
const _hoisted_3 = { class: "text-center" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    return (_openBlock(), _createElementBlock("div", {
        class: _normalizeClass(["opModal", { big: _ctx.isBig }])
    }, [
        _createElementVNode("a", {
            href: _ctx.url,
            target: "_blank"
        }, [
            _createElementVNode("img", {
                src: _ctx.imageUrl,
                style: { "width": "100%" }
            }, null, 8, _hoisted_2)
        ], 8, _hoisted_1),
        _createElementVNode("div", _hoisted_3, [
            _createElementVNode("button", {
                type: "button",
                class: "btn btn-blue mt-4",
                onClick: _cache[0] || (_cache[0] = ($event) => (_ctx.$emit('close')))
            }, "Chiudi")
        ])
    ], 2));
}
