import { renderSlot as _renderSlot, withModifiers as _withModifiers, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    return (_openBlock(), _createElementBlock("div", null, [
        _createElementVNode("form", {
            onSubmit: _cache[0] || (_cache[0] = _withModifiers(
            //@ts-ignore
            (...args) => (_ctx.submitWrapper && _ctx.submitWrapper(...args)), ["stop", "prevent"]))
        }, [
            _renderSlot(_ctx.$slots, "default")
        ], 32)
    ]));
}
