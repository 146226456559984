import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-14b64146"), n = n(), _popScopeId(), n);
const _hoisted_1 = { class: "opModal" };
const _hoisted_2 = { class: "d-flex justify-content-around" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("p", null, _toDisplayString(_ctx.text), 1),
        _createElementVNode("div", _hoisted_2, [
            _createElementVNode("button", {
                type: "button",
                class: "btn btn-danger",
                onClick: _cache[0] || (_cache[0] =
                    //@ts-ignore
                    (...args) => (_ctx.ko && _ctx.ko(...args)))
            }, "NO"),
            _createElementVNode("button", {
                type: "button",
                class: "btn btn-green",
                onClick: _cache[1] || (_cache[1] =
                    //@ts-ignore
                    (...args) => (_ctx.ok && _ctx.ok(...args)))
            }, "SI")
        ])
    ]));
}
