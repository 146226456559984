import * as OM from '@/model';
export class BgTask {
    taskId;
    taskName;
    action;
    progress;
}
export class LoginVm {
    email;
    password;
}
export class SortByParameter {
    propertyName;
    ascending;
    constructor() {
        this.propertyName = "";
        this.ascending = false;
    }
}
export class RegisterCustomerVm {
    name;
    surname;
    agenzia;
    telefono;
    email;
    password;
}
export class PasswordResetVm {
    email;
    token;
    newPassword;
}
export class UploadAttachmentVm {
    itemIdentifier;
    file;
}
export class TaskVm {
    identifier;
    product;
    creditPrice;
    title;
    startDate;
    dueDate;
    endDate;
    lastState;
    deliveryAttachmentsCount;
    showDeliveryAttachments;
}
export class AssignTaskVm {
    taskIdentifier;
    userIdentifier;
}
export class GetTaskTableFilterVm {
    showConcluded;
    officeIdentifier;
    userIdentifier;
    fromDate;
    toDate;
    constructor() {
        this.showConcluded = false;
        this.officeIdentifier = null;
        this.userIdentifier = null;
        this.fromDate = null;
        this.toDate = null;
    }
}
// export class TaskTableResponseVm {
//     totalTasks: number;
//     totalCrediti: number;
//     averageCompenso: number;
//     totalCompenso: number;
//     data: TaskTableVm[];
//     constructor(){
//         this.totalCompenso = null;
//         this.totalTasks = null;
//         this.averageCompenso = null;
//         this.data = [];
//     }
// }
// export class TaskTableVm
// {
//     identifier: string;
//     product: OM.NameIdentifier;
//     title: string;
//     customer: OM.NameIdentifier;
//     userAssigned: OM.NameIdentifier;
//     startDate: string;
//     dueDate: string;
//     lastState: OM.DateText;
//     compenso: number;
//     priorita: boolean;
// }
export class ProductVm {
    identifier;
    name;
    productType;
}
export class CreateCustomerChildVm {
    parentIdentifier;
    name;
    surname;
    email;
    password;
}
// export class TaskTrelloVm
// {
//     user: OM.NameIdentifier;
//     tasks: TaskTableVm[];
// }
export class BuyOfferVm {
    token;
    shopOfferIdentifier;
    optionName;
    customerIdentifier;
}
export class BuyPostOrderVm {
    token;
    productIdentifier;
    upsellProductName;
    customerIdentifier;
}
export class SendEmailVm {
    email;
    fromMail;
    subject;
    htmlContent;
    mailTemplateName;
}
export class CustomerServiceTableVm {
    identifier;
    createdOn;
    isCliente;
    name;
    email;
    source;
    credits;
    lastTaskOn;
    lastLoginOn;
    lastContact;
}
export class NewClientiStatVm {
    clienti;
    lead;
}
export class SpesaCollaboratoriVm {
    name;
    amount;
}
export class GetCustomerForTrelloFilterVm {
    name;
    officeIdentifier;
    showClienti;
    showNonClienti;
    stelleFilter;
    clusterFilter;
    state;
    sortByScadenza;
    onlyFreezed;
    constructor() {
        this.name = "";
        this.officeIdentifier = "";
        this.showClienti = false;
        this.showNonClienti = false;
        this.stelleFilter = [];
        this.clusterFilter = [];
        this.state = null;
        this.sortByScadenza = -1;
        this.onlyFreezed = false;
    }
}
export class RiepilogoGiornalieroVm {
    identifier;
    date;
    createdBy;
}
export class StoricoClienteVm {
    transactions;
    tasks;
}
export class CreditTransactionVm {
    date;
    price;
    credits;
    note;
    customer;
    task;
}
export class TaskWithModificheVm {
    taskName;
    taskDate;
    modificheHistory;
    creditPrice;
    product;
}
export class SaveUsersOrderVm {
    user;
    order;
}
export class ExportTaskFilterVm {
    clienteParentIdentifier;
    customerIdentifier;
    dateFrom;
    dateTo;
    constructor() {
        this.clienteParentIdentifier = null;
        this.customerIdentifier = null;
        this.dateFrom = null;
        this.dateTo = null;
    }
}
export class StripeCardDetailVm {
    cardId;
    brand;
    country;
    currency;
    expMonth;
    expYear;
    dynamicLast4;
    last4;
    holder;
}
export class StripeSaveCardVm {
    customerIdentifier;
    paymentMethodId;
}
export class MenuRoute {
    text;
    path;
}
export class Attachment extends OM.Attachment {
    fileToUpload;
    toRemove;
}
