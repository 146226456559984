class _StorageServices {
    readFromLocalStorage(lsName) {
        let data = window.localStorage.getItem(lsName);
        if (data) {
            return JSON.parse(data);
        }
        else {
            return null;
        }
    }
    setOpTableFilters(key, filters) {
        window.localStorage.setItem('_op_' + key, JSON.stringify(filters));
    }
    getOpTableFilters(key) {
        return this.readFromLocalStorage('_op_' + key);
    }
    getLoginResponse() {
        return this.readFromLocalStorage('loginResponse');
    }
    setLoginResponse(loginResponse) {
        window.localStorage.setItem('loginResponse', JSON.stringify(loginResponse));
    }
    getCustomerTaskFilter() {
        return this.readFromLocalStorage('customerTaskFilter');
    }
    setCustomerTaskFilter(filter) {
        window.localStorage.setItem('customerTaskFilter', JSON.stringify(filter));
    }
    getCustomerTaskDaysFilter() {
        return this.readFromLocalStorage('customerTaskDaysFilter');
    }
    setCustomerTaskDaysFilter(daysFilter) {
        window.localStorage.setItem('customerTaskDaysFilter', JSON.stringify(daysFilter));
    }
}
export let StorageServices = new _StorageServices();
