import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue";
const _hoisted_1 = {
    key: 0,
    id: "app"
};
const _hoisted_2 = {
    key: 0,
    class: "globalSpinner"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_mini_spinner = _resolveComponent("mini-spinner");
    const _component_router_view = _resolveComponent("router-view");
    const _component_modals_container = _resolveComponent("modals-container");
    return (_ctx.showApp)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            (_ctx.$store.state.globalSpinner > 0)
                ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                    _createVNode(_component_mini_spinner)
                ]))
                : _createCommentVNode("", true),
            _createVNode(_component_router_view),
            _createVNode(_component_modals_container)
        ]))
        : _createCommentVNode("", true);
}
