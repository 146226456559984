import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = { class: "monthNavigator" };
const _hoisted_2 = { class: "left" };
const _hoisted_3 = { class: "center" };
const _hoisted_4 = { class: "right" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
            _createElementVNode("button", {
                type: "button",
                class: "btn btn-secondary",
                onClick: _cache[0] || (_cache[0] =
                    //@ts-ignore
                    (...args) => (_ctx.prev && _ctx.prev(...args)))
            }, " < ")
        ]),
        _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.$filters.date(_ctx.localValue, 'YYYY')) + " ", 1),
        _createElementVNode("div", _hoisted_4, [
            _createElementVNode("button", {
                type: "button",
                class: "btn btn-secondary",
                onClick: _cache[1] || (_cache[1] =
                    //@ts-ignore
                    (...args) => (_ctx.next && _ctx.next(...args)))
            }, " > ")
        ])
    ]));
}
